import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Context } from '../helpers/Context';
//import FixedMessenger from './FixedMessenger';
import { ProductCard } from './ProductCard';

const ProductSearch = () => {
  const { products, getProducts, brands, getBrands } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const inputRef = useRef(null);
  const productsPerPage = 12;

  const SplitKeyword = keyword.toLowerCase().split(" ");
  const regPattern = SplitKeyword.map(word => { if (word) return `(?=.*${word})`; else return ""; }).join('');
  const regex = new RegExp(regPattern);
  const selectedBrand = brands.find(item => item.slug == "razer");
  //let filteredProducts = products.filter(item => { return item.productBrandId == selectedBrand.id });

  const filterByKeyword = (array, keyword) => {
    const lowerCaseKeyword = keyword.toLowerCase(); // Convert the keyword to lowercase for case-insensitive search

    return array.filter(item => {
      // Get all values of the object and join them into a single string
      const combinedValues = Object.values(item)
        .map(value => value?.toString().toLowerCase()) // Convert each value to string and lowercase
        .join(' '); // Combine all values into a single string

      // Check if the keyword exists in the combined values
      return combinedValues.includes(lowerCaseKeyword);
    });
  };

  const filteredProducts = filterByKeyword(products.filter(item => { return item.productBrandId === selectedBrand.id }), keyword);

  //filteredProducts = filteredProducts.filter(item => item.stock).filter(item => {
  //  if (!keyword) {
  //    return true;
  //  } else {
  //    return regex.test(item.title.toLowerCase()) || regex.test(item.tags.toLowerCase());
  //  }
  //});

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const visiblePageCount = Math.min(7, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStartPage = () => {
    if (currentPage <= Math.ceil(visiblePageCount / 2)) {
      return 1;
    } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
      return totalPages - visiblePageCount + 1;
    } else {
      return currentPage - Math.floor(visiblePageCount / 2);
    }
  };

  const startPage = getStartPage();

  useEffect(() => {
    document.title = "Product Search :: Razer";
    inputRef.current.focus();
    getBrands();
    getProducts();
  }, []);

  return (
    <>
      <div className="background-container" style={{ backgroundColor: '#000', color: 'white' }} >
      </div>
      <nav className="mb-3 crumbs">
        <Link to="/" className="breadcrumb-active text-light"><small>Home</small>
        </Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <small className="breadcrumb-not-active">Search</small>
      </nav>
      <div className="row">
        <div className="input-group mb-3 product-search">
          <input ref={inputRef} type="text" className="form-control" onChange={(e) => setKeyword(e.target.value)} placeholder="Enter Keyword..." aria-describedby="basic-addon" />
        </div>
        {filteredProducts.length === 0 ? (
          <p className="text-light">No results found for <strong>"{keyword}"</strong></p>
        ) : (
          currentProducts.map((product) => (
            <div className="d-flex col-md-6 col-lg-3 justify-content-evenly mb-3" key={product.id}>
              <ProductCard
                product={product}
                id={product.id}
                status={product.stock}
                accentColour={selectedBrand.accentBrandColour}
                mainColour={selectedBrand.secondaryBrandColor}
                cardColour={selectedBrand.primaryBrandColour}
                textColour={selectedBrand.textColour}
                imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Razer_wordmark.png"}
                currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                title={product.title}
                description={product.shortDescription}
              />
            </div>
          )))}
        {filteredProducts.length > productsPerPage && (<div className="pagination-container pagination text-light">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="pagination-newer text-light">
            Previous
          </button>
          {Array.from({ length: Math.min(visiblePageCount, totalPages) }).map((_, index) => {
            const pageNumber = startPage + index;
            return (
              pageNumber > 0 &&
              pageNumber <= totalPages && (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  disabled={currentPage === pageNumber}
                  className={currentPage === pageNumber ? "pagination-active ellipsis-button" : "ellipsis-button text-light"}
                >
                  {pageNumber}
                </button>
              )
            );
          })}

          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="pagination-older text-light">
            Next
          </button>
        </div>)}
      </div>
      {/*<FixedMessenger /> */}
    </>
  );
};

export default ProductSearch;