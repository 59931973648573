import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { FormGroup, Label, Input, FormText, Button } from "reactstrap";

function ResetPassword() {
  const { id } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fieldRequired = (value, errorState, errorMessage) => {
    if (!value) {
      setError(errorState, errorMessage);
    } else {
      setError(errorState, null);
    }
  };

  const setError = (errorState, errorMessage) => {
    switch (errorState) {
      case 'errPassword':
        setPasswordError(errorMessage);
        break;
      case 'errConfirmPassword':
        setConfirmPasswordError(errorMessage);
        break;
      default:
        break;
    }
  };

  const passwordFieldRequired = (isMatch, errorState, errorMessage) => {
    if (!isMatch) {
      setError(errorState, errorMessage);
    } else {
      setError(errorState, null);
    }
  };

  const handleResetPassword = async (e) => {
    //e.preventDefault();
    setLoading(true);
    const response = await fetch('/api/login/resetpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        password,
      }),
    });

    if (response.ok) {
      setLoading(false);
      ToastUtility.show({
        title: 'Reset password', content: 'Password reset successfully', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
      });
      navigate("/login");
    } else {
      setLoading(false);
      ToastUtility.show({
        title: 'Reset password', content: 'There was an error resetting password', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  };

  useEffect(() => {
    document.title = "Password Reset :: Razer";
  }, []);

  return (

    <div className='container mt-5 '>
      <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
      </div>
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="mb-5">
            <img src="/assets/imgs/Razer_wordmark.png" className="img-fluid w-100" alt="Razer Logo" />
          </div>
          <div className="card bg-dark">
            <div className="card-body">
              <h3 className='mb=3 text-uppercase text-light'>Reset Password</h3>

              <div className="mb-3">
                <FormGroup>
                  <Label className="text-light">
                    Password
                    <span className="required-icon">*</span>
                    <span id="errPassword" className="required-icon">
                      {passwordError}
                    </span>
                  </Label>
                  <Input
                    type="password"
                    bsSize="sm"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      fieldRequired(e.target.value, 'errPassword', 'required');
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-light">
                    Confirm Password
                    <span className="required-icon">*</span>
                    <span id="errConfirmPassword" className="required-icon">
                      {confirmPasswordError}
                    </span>
                  </Label>
                  <Input
                    type="password"
                    bsSize="sm"
                    onChange={(e) => {
                      passwordFieldRequired(e.target.value === password, "errConfirmPassword", "Password doesn't match");
                    }}
                  />
                  <FormText color="light">
                    Password entered here should match the one in the above password
                    field.
                  </FormText>
                </FormGroup>

              </div>

              <div className="d-flex justify-content-between mb-3">
                <Button style={{ backgroundColor: "#44D62C", border: "none", color: "#000", fontWeight: "bold" }} size='sm' outline onClick={handleResetPassword}>Reset Password &nbsp;{!loading ? <i className="fas fa-chevron-right ms-2"></i> : < i className='fas fa-spinner fa-spin me-2'></i>}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;