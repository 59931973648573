import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await fetch('/api/login/forgotpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      setMessage('An email has been sent with a link to reset your password.');
      setLoading(false);
    } else {
      setMessage('Failed to reset password. Please check your email address.');
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Forgot Password :: Razer";
  }, []);

  return (

    <div className='container mt-5 '>
      <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
      </div>
      <div className="row">
        <div className="col-md-6 mx-auto">
          {/*<div className="mb-5">*/}
          {/*  <img src="/assets/imgs/Razer_wordmark.png" className="img-fluid w-100" alt="razer Logo" />*/}
          {/*</div>*/}
          <div className="card bg-dark">
            <div className="card-body">
              <h3 className='mb=3 text-uppercase text-light'>Forgot Password</h3>

              <div className="mb-3">

                <label className="text-light">Email
                  <span className="required-icon">*</span>
                </label>

                <input className='form-control form-control-sm' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>

              <div className="d-flex justify-content-between mb-3">
                <Button style={{ backgroundColor: "#44D62C", border: "none", color: "#000", fontWeight: "bold" }} size='sm' outline onClick={handleSubmit}>Reset Password &nbsp;{!loading ? <i className="fas fa-chevron-right ms-2"></i> : < i className='fas fa-spinner fa-spin me-2'></i>}</Button>
              </div>
              <p className="text-light">{message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;