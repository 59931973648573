import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import FixedMessenger from './FixedMessenger';
import { getCurrentDomain } from '../helpers/Lookup';

export class AboutUs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      PageContent: null,
      Banners: [],
      loading: true
    }
  }

  componentDidMount() {
    document.title = "About Razer :: Razer";

    this.loadData();
  }

  async loadData() {

    try {
      const response = await fetch('api/pagecontent/LoadPageContent/razer-about', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const PageContent = await response.json();
        this.setState({ PageContent, loading: false });

      } else {
        console.log(response.status + ":", response.statusText);
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }


  }

  RenderPageContent(Content) {
    return (
      <div className="text-light">
        <h1 className='text-uppercase'>{Content?.title}</h1>
        <div className="about-text">
          <div className="text-center">
            <h1 className="razor-title">About Razer</h1>
            <h5>Razer™ is the world’s leading lifestyle brand for gamers.</h5>

            <p>The triple-headed snake trademark of Razer is one of the most recognized logos in the global gaming and esports communities. With a fan base that spans every continent, the company has designed and built the world’s largest gamer-focused ecosystem of hardware, software and services.</p>
            <p>Razer’s award-winning hardware includes high-performance gaming peripherals and Blade gaming laptops.</p>
            <p>Razer’s software platform, with over 175 million users, includes Razer Synapse (an Internet of Things platform), Razer Chroma RGB (a proprietary RGB lighting technology system supporting thousands of devices and hundreds of games/apps), and Razer Cortex (a game optimizer and launcher).</p>
            <p>Founded in 2005, Razer is dual headquartered in Irvine (California) and Singapore with regional headquarters in Hamburg and Shanghai. Razer has 19 offices worldwide and is recognized as the leading brand for gamers in the USA, Europe and China.</p>
            <p>Razer is officially distributed by Apex Interactive in South Africa and the Razer Shop SA site is powered by TriggerCraft. </p>
            <p>To find out more about Razer’s history, visit the main site: <a href="https://www.razer.com/about-razer" target="_blank" rel="noreferrer">https://www.razer.com/about-razer</a></p>
          </div>
        </div>
      </div>
    )
  }

  render() {

    const content = this.state.loading ? <em> Loading...</em> : this.RenderPageContent(this.state.PageContent);
    return (
      <>
        <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
        </div>
        <nav className="mb-3 crumbs">
          <Link to="/" className="breadcrumb-active text-light"><small>Home</small>
          </Link>
          <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
          <small className="breadcrumb-not-active">About Razer</small>
        </nav>
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto">
              {content}
            </div>
          </div>
        </div>
      </>
    );
  }


}