import React, { useRef, useState, useEffect } from 'react';
import { ProductCard } from '../ProductCard';

const ProductScroller = (Params) => {
  const divRef = useRef(null);


  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);


  useEffect(() => {
    setLoading(false);
    setOffset(Params.Products.length);
    if (Params.Id && offset <= 3) {
      try {
        document.getElementById(`prev-slide-${Params.Id}`).style.display = "none";
        document.getElementById(`next-slide-${Params.Id}`).style.display = "none";
      } catch (e) {

      }
      
    };
  }, [Params]);


  const dynamicGridStyleNew = {
    display: 'grid',
    gridTemplateColumns: `repeat(${Params.Products.length < 3 ? 3 : Params.Products.length}, 1fr)`,
    gap: '18px',
    fontSize: 0,
    listStyle: 'none',
    marginBottom: '30px',
    overflowX: 'auto',
    scrollbarWidth: 'none',
  };


  const handleScroll = (direction) => {

    const width = document.getElementById("sliderContainer").clientWidth;

    const imageList = document.getElementById(`slider-${Params.Id}`);
    imageList.scrollBy({ left: (width * direction), behavior: "smooth" });
    //console.log("elements: ", offset);
    console.log("max width: ", offset * width);
    console.log("current offset: ", imageList.scrollLeft);

    //const btnLeft = document.getElementById(`prev-slide-${Params.Id}`);
    //btnLeft.style.display = imageList.scrollLeft <= 0 ? "none" : "flex";
    setTimeout(() => {
     // console.log(offset);

      if ( offset <= 3) {
        try {
          document.getElementById(`prev-slide-${Params.Id}`).style.display = "none";
          document.getElementById(`next-slide-${Params.Id}`).style.display = "none";
        } catch (e) {

        }

      } else
       {
        document.getElementById(`prev-slide-${Params.Id}`).style.display = imageList.scrollLeft < 200 ? "none" : "flex";
        document.getElementById(`next-slide-${Params.Id}`).style.display = imageList.scrollLeft > ((offset - 3) * width) - 200 ? "none" : "flex";
      }
      //const btnRight = document.getElementById(`next-slide-${Params.Id}`);
      console.log("current offset: ", imageList.scrollLeft);
    }, 500);
  }

  const scrollLeft = (ev) => {
    //console.log("scrolling left", ev);
    handleScroll(1);
  };

  const scrollRight = (ev) => {
    //console.log("scrolling right", ev);
    handleScroll(-1);
  };

  const RenderTitle = (Title, SubTitle) => {
    if (Title && SubTitle)
      return (<div className="razor-title mb-4"><h2>{Title}</h2><h3>{SubTitle}</h3></div>)
    else if (Title)
      return (<div className="razor-title mb-4"><h2>{Title}</h2></div>)
  }

  const RenderScroller = () => {


    if (loading) {
      return (<>{RenderTitle(Params.Title, Params.SubTitle)}<div className='text-white mb-4'><i className='fas fa-spinner fa-spin me-2 '></i>Please wait loading products...</div></>)
    }
    else {
      const products = Params.Products;
      const brand = Params.Brand;
      //console.log(products)
      //console.log(brand)
      if (products.length > 0)
        return (
          <>
            {RenderTitle(Params.Title, Params.SubTitle)}
            <div className="razer-container">
              <div className="slider-wrapper" id="sliderContainer">
                <i id={`prev-slide-${Params.Id}`} className="slide-button fa-solid fa-circle-arrow-left prev-slide" onClick={scrollRight} ></i>
                <ul className="image-list px-0" style={dynamicGridStyleNew} ref={divRef} id={`slider-${Params.Id}`}>
                  {products.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      id={product.id}
                      status={product.stock}
                      accentColour={brand.accentBrandColour}
                      mainColour={brand.primaryBrandColour}
                      cardColour={brand.secondaryBrandColor}
                      textColour={brand.textColour}
                      imageURL={product.images ? product.images.split(", ")[0] : brand && brand.brandImages && JSON.stringify(brand.brandImages)}
                      currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                      prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                      title={product.title}
                      description={product.shortDescription}
                    />
                  ))}
                </ul>
                <i id={`next-slide-${Params.Id}`} className="slide-button fa-solid fa-circle-arrow-right next-slide" onClick={scrollLeft} style={{display: (offset <= 3 ? "none" : "flex")}}></i>
              </div>
            </div>
          </>
        )
    }
  }

  return RenderScroller();

};

export default ProductScroller;