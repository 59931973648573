import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Context } from '../helpers/Context';
import ProductScroller from './home/ProductScroller';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const Home = () => {
  const [loading, setLoading] = useState(true);
  const { products, getProducts, brands, getBrands, categories, getCategories, subcategories, getSubcategories } = useContext(Context);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const selectedBrand = brands.find(item => item.slug === "razer");
  let filteredProducts = products && products.filter(item => item.productBrandId === selectedBrand?.id);

  const bannerItems = [
    {
      src: '/assets/Category-Images-Razer/razer-free-gift-banner-2.jpg',
      altText: 'Free Gift with All Orders',
      caption: 'Free Gift with All Orders',
      key: 1,
    },
    {
      src: '/assets/Category-Images-Razer/razer-fortnite-range-coming-soon-banner.jpg',
      altText: 'Fortnite coming soon!',
      caption: 'Fortnite coming soon!',
      key: 2,
    },
   
  ];


  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = bannerItems.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="img-fluid" />
      </CarouselItem>
    );
  });

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === bannerItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? bannerItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };


  const scrollTo = (sectionId) => {
    scrollToSection(sectionId);
  };


  useEffect(() => {
    document.title = "Products :: Razer";

    const fetchData = async () => {
      try {
        // Use Promise.all to wait for both requests to complete
        await getBrands();
        await getProducts();
        await getCategories();
        await getSubcategories();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

  }, []);

  const getAllProductsInCategory = (ParentCategoryId) => {
    const filteredIds = subcategories
      .filter(item => item.categoryId === ParentCategoryId) // Filter items by categoryId
      .map(item => item.id);
    let result = [];
    products.forEach((element, idx) => {
      const prodCats = JSON.parse(element.selectedCategories);

      //console.log(prodCats)

      const hasCommonElements = filteredIds.some(item => prodCats.includes(item));
      //console.log(hasCommonElements, idx);
      if (hasCommonElements)
      result.push(element);
    });
    return result;
  }



  const renderScrolls = () => {
    if (loading) {
      return <div className="text-center">Loading...</div>;
    }
    const first20Items = filteredProducts.toSorted((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)).slice(0, 20);
    /*<ProductScroller Brand={selectedBrand} Products={first20Items} Id="OnlineExclusive" Title="RAZER ONLINE EXCLUSIVES" SubTitle="Check out our latest releases to secure the most up-to-date upgrades for your setup" />*/
    return (
      <>
        
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(10)} Id="OnlineExclusive" Title="RAZER ONLINE EXCLUSIVES" SubTitle="Check out our latest releases to secure the most up-to-date upgrades for your setup" />
        <ProductScroller Brand={selectedBrand} Products={filteredProducts} Id="BestSellers" Title="BEST SELLERS" SubTitle="Join the hype train with the hottest products in our arsenal" />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(13)} Id="Mice" Title="MICE"  />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(12)} Id="Keyboards" Title="KEYBOARDS" />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(11)} Id="Headsets" Title="HEADSETS" />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(3)} Id="Audio" Title="SPEAKERS" />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(17)} Id="ContentCreation" Title="CONTENT CREATION" />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(9)} Id="Console" Title="CONSOLE" />
        <ProductScroller Brand={selectedBrand} Products={getAllProductsInCategory(1)} Id="Accessories" Title="ACCESSORIES" />
      </>
    );
  }

  return (
    <>
      <div className="background-container"></div>

      <div className="" id="exclusiveSection">
        <div className="text-center razer-categories mt-3">
          <div onClick={() => { scrollTo('exclusiveSection'); }}>
            <img src="/assets/Category-Images-Razer/Online-Exclusive.png" alt="category" />
            <h6>Online Exclusives</h6>
          </div>
          <Link to={`/categories/razer/${"Mice".replaceAll(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Mice.png" alt="category" />
            <h6>Mice</h6>
          </Link>
          <Link to={`/categories/razer/${"Keyboards".replace(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Keyboards.png" alt="category" />
            <h6>Keyboards</h6>
          </Link>
          <Link to={`/categories/razer/${"Headsets".replace(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Headsets.png" alt="category" />
            <h6>Headsets</h6>
          </Link>
          <Link to={`/categories/razer/${"Audio".replace(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Audio.png" alt="category" />
            <h6>Speakers</h6>
          </Link>
          <Link to={`/categories/razer/${"Streaming".replace(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Streaming.png" alt="category" />
            <h6>Content Creation</h6>
          </Link>
          <Link to={`/categories/razer/${"Controllers".replace(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Controllers.png" alt="category" />
            <h6>Console</h6>
          </Link>
          <Link to={`/categories/razer/${"Accessories".replace(" ", "_").toLowerCase()}`}>
            <img src="/assets/Category-Images-Razer/Accessories.png" alt="category" />
            <h6>Accessories</h6>
          </Link>
        </div>
       

        <Carousel activeIndex={activeIndex} next={next} previous={previous} ride="carousel" interval={5000} fade={ true} >
          <CarouselIndicators items={bannerItems} activeIndex={activeIndex} onClickHandler={goToIndex} />
          {slides}
        </Carousel>

        
        <div className="razor-title  my-4">
          <h1>DISCOVER RAZER GEAR</h1>
          <h3>Razer mice, keyboards, headsets, laptops &amp; more</h3>
        </div>

        {renderScrolls()}

      </div>
    </>
  );
};

export default Home;